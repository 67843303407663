import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { TokenInterceptor } from "../interceptors/token.interceptor";
import { ApiService } from "../modules/api/services/api.service";
import { SharedModule } from "../shared/shared.module";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { AngularErrorHandler } from "../handlers/angular-error.handler";


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
  ],
  providers: [
    {provide: ErrorHandler, useClass: AngularErrorHandler},
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
