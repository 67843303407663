import {Injectable, OnDestroy} from '@angular/core';
import { LocalStorageService } from "./local-storage.service";
import { TokenObject } from "../../interfaces/token-object.interface";
import { ApiService } from "../api/services/api.service";
import * as _ from "lodash";
import {BehaviorSubject, timer} from "rxjs";
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { environment } from "../../environments/environment";
import { DialogService } from "../../services/dialog.service";
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public timer = timer(0,300000);
  subscription;
  rememberMe: boolean;
  private userLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private router: Router,
    private cookieService: CookieService,
    private dialogService: DialogService,
) { }

  getToken() {
    const localStorageToken = this.localStorageService.getItem('token');
    const cookieToken = this.cookieService.get('token');

    this.rememberMe = Boolean(localStorageToken);

    if (localStorageToken && cookieToken) {
      const parseTokenLocalStorage = JSON.parse(localStorageToken);
      const parseTokenCookieStorage = JSON.parse(cookieToken);

      if ((parseTokenLocalStorage && this.isTokenValid(parseTokenLocalStorage)) && (parseTokenCookieStorage && this.isTokenValid(parseTokenCookieStorage))) {
        this.removeToken();
        return null;
      }
    }

    const token = localStorageToken || cookieToken;

    if (token && this.isTokenValid(JSON.parse(token))) {
      return JSON.parse(token);
    } else {
      return null;
    }
  }

  setToken(token: TokenObject) {
    if (this.rememberMe) {
      this.localStorageService.setItem('token', JSON.stringify(token))
    } else {
      this.setCookie(token);
    }
    this.userLoggedIn.next(true);
  }

  removeToken() {
    const localStorageToken = this.localStorageService.getItem('token');
    const cookieToken = this.cookieService.get('token');

    if (localStorageToken) {
      this.localStorageService.removeItem('token');
    }

    if (cookieToken) {
      this.deleteCookie()
    }

    this.userLoggedIn.next(false);
  }

  exchangeToken() {
    return this.apiService.post('api-token/exchange');
  }

  invalidateToken() {
    return this.apiService.delete('api-token/invalidate');
  }

  isTokenValid(token) {
    return _.isObject(token);
  }

  isUserLoggedIn() {
    return this.userLoggedIn;
  }

  clearTimer() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  refreshToken() {
    if (!this.subscription) {
      this.subscription = this.timer.subscribe(() => {
        this.exchangeToken().subscribe((token) => {
          this.setToken(token)
        })
      })
    }
  }

  setCookie(token) {
    this.cookieService.set(
      'token',
      JSON.stringify(token),
      moment().add('days', 7).toDate(),
      environment.cookieConfig.path,
      null,
      environment.cookieConfig.secure
    )
  }

  deleteCookie() {
    this.cookieService.delete('token', environment.cookieConfig.path);
  }

  logout() {
    this.invalidateToken()
      .subscribe({
        next: () => {
          this.clearTokenRedirect();
        },
      })
  }

  clearTokenRedirect() {
    this.clearTimer();
    this.removeToken();
    this.router.navigate(['/', 'login']);
  }
}
