import { Injectable, NgZone } from '@angular/core';
import { ApiOptions } from '../interfaces/api-options';
import { DialogService } from "../services/dialog.service";
import { ApiService } from "../modules/api/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class AndroidService {
  nativeInterface: {
    gameStarted: (fake?: number) => void;
    closeApp: () => void;
  } = window['NativeInterface'];

  constructor(
    private zone: NgZone,
    private dialogService: DialogService,
    private apiService: ApiService,
  ) {
    this.setAndroidServiceInWindow();
  }

  private setAndroidServiceInWindow() {
    window['AndroidBridge'] = this;
  }

  closeApp() {
    if (this.nativeInterface) {
      this.nativeInterface.closeApp();
    }
  }

  closeWindow() {
    if (window.location.pathname === '/main/home' || window.location.pathname === '/main/login') {
      this.zone.run(() => {
        this.dialogService.openAlert({
          data: {
            title: 'Uwaga!',
            description: 'Czy na pewno chcesz zamknąć aplikację?',
            isConfirmAlert: true,
          }
        }, (confirm) => {
          if (confirm) {
            this.closeApp();
          }
        });
      });
    }
  }

  gameStarted(fake?: number) {
    if (this.nativeInterface) {
      this.nativeInterface.gameStarted(fake);
    }
  }

  registerForPush(token, appVersion) {
    this.pushTokenRequest(token, appVersion)
      .subscribe(() => {
      });
  }

  pushTokenRequest(token, appVersion) {
    const options: ApiOptions = {
      body: {
        token,
        app_version: appVersion,
        type: 1
      }
    };

    return this.apiService.post('push-token', options);
  }
}
