import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floor'
})
export class FloorPipe implements PipeTransform {
  transform(value: any, unit?: string): any {
    if (unit === 'float') {
      value = Math.floor(Number((value * 100).toFixed(2))) / 100;
    } else {
      value = Math.floor(value);
    }

    return value;
  }

}
