import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomEncoder } from "../helpers/custom-encoder.helper";
import { ApiOptions } from "../../../interfaces/api-options";
import { CookieService } from "ngx-cookie-service";
import { APP_CONFIG } from 'src/config';

@Injectable()
export class ApiService {
  apiUrl = APP_CONFIG.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) {
  }

  private getOptionsDefault(key?) {
    const optionsDefault = {
      headers: {
        'x-token': this.getApiToken()?.token,
        'Content-Type': 'application/json'
      }
    };

    if (key) {
      return optionsDefault[key];
    }

    return optionsDefault;
  }

  private setHeaders(options: ApiOptions, method: string) {
    let optionsHeaders = options.headers || {};

    let httpHeaders = new HttpHeaders();

    optionsHeaders = {...this.getOptionsDefault('headers'), ...optionsHeaders};

    if (options.sendRequestWithoutToken) {
      delete optionsHeaders['x-token'];
    }

    if (options.contentTypeAuto || method === 'GET' || !options.body) {
      delete optionsHeaders['Content-Type'];
    }

    Object.keys(optionsHeaders).map((key) => {
      httpHeaders = httpHeaders.append(key, optionsHeaders[key]);
    });

    return httpHeaders;
  }

  private setParams(options: ApiOptions) {
    let optionsParams = {...options.params};

    let httpParams = new HttpParams({encoder: new CustomEncoder()});

    optionsParams = {...this.getOptionsDefault('params'), ...optionsParams};

    if (options.isNotShowLoading) {
      optionsParams['isNotShowLoading'] = true;
    }

    Object.keys(optionsParams).map((key) => {
      if (this.checkIsNotEmptyElement(optionsParams[key])) {
        httpParams = httpParams.append(key, optionsParams[key]);
      }
    });

    return httpParams;
  }

  private setBody(optionsBody) {
    if (!optionsBody) {
      return null;
    }

    if (optionsBody instanceof FormData) {
      return optionsBody;
    } else {
      const body = {...optionsBody};

      if (optionsBody) {
        Object.keys(optionsBody).forEach((key) => {
          let value = body[key];

          if (value === null) {
            delete body[key];
          }
        });
      }

      return body;
    }
  }

  private prepareRequestOptions(options: ApiOptions = {}, method: string) {
    const finalOptions = {
      headers: this.setHeaders(options, method),
      params: this.setParams(options),
      body: this.setBody(options.body),
      observe: undefined,
      responseType: options.responseType || 'json',
    };

    if (options.responseWithHeaders) {
      finalOptions.observe = 'response';
    }

    return finalOptions;
  }

  private checkIsNotEmptyElement(value: any) {
    return (value || value === 0);
  }

  request(method, path, options) {
    return <Observable<any>>this.httpClient.request(method, `${this.apiUrl}${path}`, this.prepareRequestOptions(options, method));
  }

  post(path: string, options?: ApiOptions) {
    return this.request('POST', path, options);
  }

  get(path: string, options?: ApiOptions) {
    return this.request('GET', path, options);
  }

  delete(path: string, options?: ApiOptions) {
    return this.request('DELETE', path, options);
  }

  put(path: string, options?: ApiOptions) {
    return this.request('PUT', path, options);
  }

  patch(path: string, options?: ApiOptions) {
    return this.request('PATCH', path, options);
  }


  getApiToken() {
    const localStorageToken = localStorage.getItem('token');
    const cookieToken = this.cookieService.get('token');

    const token = cookieToken || localStorageToken;

    if (token) {
      return JSON.parse(token);
    } else {
      return null;
    }
  }
}
