<div class="modal-content" [ngClass]="data.bgClass">
  <div class="modal-header" *ngIf="data.title">
    <div class="modal-title">
      {{ data.title }}
    </div>
  </div>

  <div
    class="modal-body"
    [ngClass]="data.classes"
    [innerHTML]="data.description"
  ></div>

  <div class="modal-footer" [class.center]="!data.isConfirmAlert">
    <button class="primary" (click)="close()">
      {{ data.button?.close?.label || (data.isConfirmAlert ? 'Anuluj' : 'Zamknij') }}
    </button>

    <button *ngIf="data.isConfirmAlert" class="primary" (click)="close({isConfirm: true})">
      {{ data.button?.confirm?.label || 'Ok' }}
    </button>
  </div>
</div>
