export function transformErrorApi(errResp) {
  let errors = "";

  if (errResp.status === undefined && errResp.error === undefined) {
    console.log(errResp);

    if (window) {
      if (window['Sentry']) {
        window['Sentry'].captureMessage(errResp);
      }

      return errors;
    }
  }

  if (errResp.status != 500 && errResp.status != 502 && errResp.status != 0) {
    //if native xhr response
    if (!errResp.error) {
      errResp.error = JSON.parse(errResp.response);
    }

    let jsonBody = errResp.error;

    if (jsonBody.hasOwnProperty('errors')) {
      let errorsApi = jsonBody.errors;

      if (errorsApi['errors']) {
        errorsApi.errors.forEach((error) => {
          errors += error + '<br/>';
        })
      }
    }

    if (jsonBody.hasOwnProperty('errors') && jsonBody['errors'].hasOwnProperty('children')) {
      for (let key in jsonBody.errors.children) {
        if (jsonBody.errors.children[key].hasOwnProperty('errors')) {
          jsonBody.errors.children[key].errors.forEach((error) => {
            if (dictionaryError[key]) {
              errors += (dictionaryError[key] || key) + ': ';
            }
            errors += error + '<br/>';
          })
        }
      }
    }

    if (jsonBody.hasOwnProperty('message')) {
      if (errors.length == 0) {
        errors += jsonBody.message + '<br/>';
      }
    }
  }

  return errors;
}

export const dictionaryError = {
  'first_name': 'Imię',
  'last_name': 'Nazwisko',
  'old_password': 'Aktualne hasło',
  'new_password': 'Nowe hasło',
  'email': 'E-mail',
  'firstName': 'Imię',
  'lastName': 'Nazwisko',
  'pesel': 'Pesel',
  'phone': 'Nr telefonu',
  'password': 'Hasło',
  'hrid': 'HRID',
  'challenge': 'Pierwsze hasło',
  'code': 'Kod',
};
