import { Injectable, NgZone } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenService } from "../modules/services/token.service";
import moment from 'moment';
import { WHITELIST_INTERCEPTOR_ERRORS } from "../consts/whitelist-interceptor-errors.const";
import { DialogService } from "../services/dialog.service";
import { transformErrorApi } from "../helpers/transform-error-api.helper";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  WHITE_LIST = [
    'user-settings/password-change',
    'companies'
  ];

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private dialogService: DialogService,
    private ngZone: NgZone,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    const cloned = req.clone();
    const isUrlOnWhitelist = this.WHITE_LIST.find(url => req.url.indexOf(url) !== -1);

    try {
      if (req.headers.has('token') && req.headers.has('X-Token') && !isUrlOnWhitelist) {
        const tokenHeader = req.headers.get('X-Token');

        if (!tokenHeader) {
          this.tokenExpired();
        }

        if (this.isTokenExpired()) {
          this.tokenExpired();
        }
      }
    } catch(e) {
      this.router.navigate(['/']);
    }

    return next.handle(cloned).pipe(
      catchError(error => {
        if (!this.isOnWhitelist(cloned.url)) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              this.openAlert({
                description: transformErrorApi(error),
              });
              this.tokenService.clearTokenRedirect();
              break;
          }
        }

        return throwError(error);
      }));
  }

  tokenExpired() {
    this.tokenService.clearTokenRedirect();
    if (window['Sentry']) {
      window['Sentry'].captureMessage(this);
    }
    this.router.navigate(['/login']);
    return throwError(() => 'tokenExpired');
  }

  isTokenExpired(): boolean {
    if (!this.tokenService.getToken().token) {
      return true;
    }

    const currentDate = moment();
    const tokenExpiredAtDate = moment(this.tokenService.getToken()?.expires_at);

    return currentDate.isAfter(tokenExpiredAtDate);
  }

  isOnWhitelist(url: string) {
    return WHITELIST_INTERCEPTOR_ERRORS.some(u => url.includes(u));
  }

  openAlert({description}) {
    this.ngZone.run(() => {
      this.dialogService.openAlert({
        data: {
          description,
        }
      });
    });
  }
}
