import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertComponent } from "../shared/dialogs/alert/alert.component";
import { Alert } from "../shared/dialogs/alert/interfaces/alert.interface";

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  openDialogs: any[] = [];

  constructor(
    private dialog: MatDialog,
  ) {
  }

  public open(component: any, config: MatDialogConfig = {data: {}}, onCloseCallback?) {
    const dialogRef = this.dialog.open(component, config);
    this.openDialogs.push(dialogRef);

    dialogRef.afterClosed().subscribe((data) => {
      if (onCloseCallback) {
        onCloseCallback(data);
      }

      this.removeDialog(dialogRef);
    });

    return dialogRef;
  }

  public closeAll() {
    this.openDialogs.forEach((dialogRef) => {
      dialogRef.close();
      dialogRef = null;
    });
  }

  private removeDialog(dialogRef) {
    // REMOVE NULL/EMPTY ELEMENT START
    const idx = this.openDialogs.indexOf(dialogRef);
    if (idx !== -1) {
      delete this.openDialogs[idx];
    }
    this.openDialogs = this.openDialogs.filter((dialog) => {
      return (dialog !== (undefined || '' || null));
    });
    // REMOVE NULL/EMPTY ELEMENT END
  }

  public closeActive() {
    return this.openDialogs.pop().close();
  }

  public openAlert(config: MatDialogConfig & { data: Alert }, onCloseCallback?) {
    this.open(AlertComponent, config, onCloseCallback)
  }
}
