import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToHourPipe } from "./pipes/to-hour.pipe";
import { AlertComponent } from "./dialogs/alert/alert.component";
import { MatDialogModule } from "@angular/material/dialog";
import { DialogService } from "../services/dialog.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatExpansionModule } from "@angular/material/expansion";
import { FloorPipe } from "./pipes/floor.pipe";
import { PhonePatternPipe } from './pipes/phone-pattern.pipe';


@NgModule({
  declarations: [
    ToHourPipe,
    AlertComponent,
    FloorPipe,
    PhonePatternPipe,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatExpansionModule
  ],
  providers: [
    ToHourPipe,
    FloorPipe,
    DialogService,
  ],
    exports: [
        ToHourPipe,
        MatDialogModule,
        MatFormFieldModule,
        FloorPipe,
        PhonePatternPipe,
    ]
})

export class SharedModule { }
